import { useState } from "react";
import axios from "axios";
import { toast } from "../../../core";

export const useAddQuestion = (item: any) => {
    const [forms, setForms] = useState<any[]>([]);

    const addForm = () => {
        const newForm = {
            formId: forms.length + 1,
            selectedOption: "Text",
            inputOption: "",
            choices: [] as string[],
        };
        setForms([...forms, newForm]);
    };

    const removeForm = (formId: number) => {
        const updatedForms = forms.filter((form) => form.formId !== formId);
        setForms(updatedForms);
    };

    const handleOptionChange = (formId: number, value: string) => {
        const updatedForms = forms.map((form) =>
            form.formId === formId ? { ...form, selectedOption: value } : form
        );
        setForms(updatedForms);
    };

    const handleInputChange = (formId: number, value: string) => {
        const updatedForms = forms.map((form) =>
            form.formId === formId ? { ...form, inputOption: value } : form
        );
        setForms(updatedForms);
    };

    // const handleChoicesChange = (formId: number, value: string) => {
    //     const updatedForms = forms.map((form) =>
    //         form.formId === formId ? { ...form, choices: [value] } : form
    //     );
    //     setForms(updatedForms);
    // };
    const handleChoicesChange = (formId: number, value: string) => {
        const updatedForms = forms.map((form) =>
            form.formId === formId
                ? { ...form, choices: value.split(",") }
                : form
        );
        setForms(updatedForms);
    };

    const handleFormSubmit = async (
        formId: number,
        formData: any,
        setQuestionOpen: any
    ) => {
        const access_token = `Token ${localStorage.getItem("access")}`;
        const url = "https://app.qik-check.com/campaign/create-questions/";
        const config = {
            headers: {
                Authorization: access_token,
            },
        };

        const { selectedOption, inputOption, choices } = formData;

        if (selectedOption === "") {
            return alert("Select an option");
        }
        if (inputOption === "") {
            return alert("Give your question");
        }

        const data = new FormData();
        data.append("campaignName", item.name);
        data.append("type", selectedOption);
        data.append("question", inputOption);

        if (
            selectedOption === "Multiple Choice" ||
            selectedOption === "Checkbox"
        ) {
            data.append("choices", JSON.stringify(choices));
        }

        try {
            const response = await axios.post(url, data, config);
            toast.success();

            // Reset the form values
            const updatedForms = forms.map((form) =>
                form.formId === formId
                    ? {
                          ...form,
                          selectedOption: "Text",
                          inputOption: "",
                          choices: [] as string[],
                      }
                    : form
            );
            setQuestionOpen(false);
            setForms(updatedForms);
        } catch (err) {
            toast.error();
        }
    };

    return {
        forms,
        addForm,
        removeForm,
        handleFormSubmit,
        handleOptionChange,
        handleInputChange,
        handleChoicesChange,
    };
};
