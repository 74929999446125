import Modal from "react-modal";

import { campaignFeatureStyle } from "../../admin.style";
import CampaignFeatureElement from "./campaign-feature-element";
import StatusDropdown from "./campaign-status.dropdown";

function CampaignFeatureModal({ featureOpen, setFeatureOpen, item }: any) {
    return (
        <Modal
            style={campaignFeatureStyle}
            isOpen={featureOpen}
            onRequestClose={() => setFeatureOpen(false)}
            contentLabel="Example Modal"
        >
            <button
                className="payment-modal__close-btn close"
                onClick={() => setFeatureOpen(false)}
            >
                <span>&times;</span>
            </button>
            <div>
                <div className="row">
                    <div className="col">
                        <h3>Campaign Feature :</h3>
                        <p>
                            See the below features for{" "}
                            <span className="fw-bold fs-4">"{item.name}"</span>:{" "}
                            campaign
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <StatusDropdown item={item} />
                    </div>

                    <div className="col-lg-7">
                        <CampaignFeatureElement
                            item={item}
                            className={
                                item.allow_duplicate === "Yes"
                                    ? "btn btn-danger mr-2 mb-2"
                                    : "btn btn-primary mr-2 mb-2"
                            }
                            element={
                                item.allow_duplicate === "Yes"
                                    ? "Restrict Duplicates"
                                    : "Allow Duplicates"
                            }
                            event="dupplicate"
                        />
                        <CampaignFeatureElement
                            item={item}
                            className={
                                item.allow_duplicate_across_campaigns === "Yes"
                                    ? "btn btn-danger mr-2"
                                    : "btn btn-primary mr-2"
                            }
                            element={
                                item.allow_duplicate_across_campaigns === "Yes"
                                    ? "Restrict Duplicates in Campaigns"
                                    : "Allow Duplicates in Campaigns"
                            }
                            event="across"
                        />
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default CampaignFeatureModal;
