// import Modal from "react-modal";
// import { customStyles } from "../../admin.style";
// import { Typography } from "../../../../core";
// import { useAddQuestion } from "../../hooks/useAddQuestion";
// import { useState } from "react";

// Modal.setAppElement("#root");

// export function AddQuestion({ questionOpen, setQuestionOpen, item }: any) {
//     const {
//         selectedOption,
//         inputOption,
//         choices,
//         handleOptionChange,
//         handleInputChange,
//         handleChoicesChange,
//         handleSubmit,
//     } = useAddQuestion(item);

//     const [state, setState] = useState([1]);

//     const createBox = () => {
//         setState([...state, 0]);
//     };

//     const onDelete = (id: any) => {
//         function filterArra(number: any, index: any) {
//             if (id === index) return false;
//             else return true;
//         }

//         const updatedArra = state.filter(filterArra);
//         setState(updatedArra);
//     };

//     return (
//         <div>
//             <Modal
//                 isOpen={questionOpen}
//                 onRequestClose={() => setQuestionOpen(false)}
//                 style={customStyles}
//                 contentLabel="Example Modal"
//             >
//                 <button
//                     className="payment-modal__close-btn"
//                     onClick={() => setQuestionOpen(false)}
//                 >
//                     <span>&times;</span>
//                 </button>
//                 <Typography
//                     className="text-center mb-2 mt-2 question-modal-heading"
//                     element={`CAMPAIGN : ${item.name ? item.name : "current"}`}
//                 />

//                 <div className="col-lg-6 me-auto ms-auto text-center mt-4">
//                     <button className="btn btn-primary" onClick={createBox}>
//                         Add Box
//                     </button>
//                     <form onSubmit={handleSubmit}>
//                         {(() =>
//                             state.map((number, index) => (
//                                 <div key={index} className="singlebox">
//                                     <div className="form-group">
//                                         <label htmlFor="selectOption">
//                                             Select Option:
//                                         </label>
//                                         <select
//                                             className="form-control"
//                                             id="selectOption"
//                                             value={selectedOption}
//                                             onChange={handleOptionChange}
//                                         >
//                                             <option value="Text">TEXT</option>
//                                             <option value="Multiple Choice">
//                                                 MCQ
//                                             </option>
//                                             <option value="Checkbox">
//                                                 Checkbox
//                                             </option>
//                                         </select>
//                                     </div>
//                                     <div className="form-group">
//                                         <label htmlFor="inputOption">
//                                             QUESTION:
//                                         </label>
//                                         <input
//                                             type="text"
//                                             className="form-control"
//                                             id="inputOption"
//                                             placeholder="Enter a question"
//                                             value={inputOption}
//                                             onChange={handleInputChange}
//                                         />
//                                     </div>
//                                     {selectedOption === "Multiple Choice" && (
//                                         <div className="form-group">
//                                             <label htmlFor="choices">
//                                                 OPTIONS:
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 id="choices"
//                                                 placeholder="Enter a question"
//                                                 value={choices}
//                                                 onChange={handleChoicesChange}
//                                             />
//                                         </div>
//                                     )}
//                                     {selectedOption === "Checkbox" && (
//                                         <div className="form-group">
//                                             <label htmlFor="choices">
//                                                 CHECKBOX:
//                                             </label>
//                                             <input
//                                                 type="text"
//                                                 className="form-control"
//                                                 id="choices"
//                                                 placeholder="Enter a question"
//                                                 value={choices}
//                                                 onChange={handleChoicesChange}
//                                             />
//                                         </div>
//                                     )}
//                                     <button
//                                         className="btn btn-danger m-2"
//                                         onClick={() => {
//                                             onDelete(index);
//                                         }}
//                                     >
//                                         delete
//                                     </button>
//                                 </div>
//                             )))()}
//                         <button type="submit" className="btn btn-primary mt-2">
//                             Submit
//                         </button>
//                     </form>
//                 </div>
//             </Modal>
//         </div>
//     );
// }

import Modal from "react-modal";
import { questionStyles } from "../../admin.style";
import { Typography } from "../../../../core";
import { useAddQuestion } from "../../hooks/useAddQuestion";
import { useState } from "react";

Modal.setAppElement("#root");

export function AddQuestion({ questionOpen, setQuestionOpen, item }: any) {
    const {
        forms,
        addForm,
        removeForm,
        handleFormSubmit,
        handleOptionChange,
        handleInputChange,
        handleChoicesChange,
    } = useAddQuestion(item);

    const handleFormSubmitAll = () => {
        forms.forEach((form: any) => {
            handleFormSubmit(
                form.formId,
                {
                    selectedOption: form.selectedOption,
                    inputOption: form.inputOption,
                    choices: form.choices,
                },
                setQuestionOpen
            );
        });
    };

    return (
        <div className="overflow-auto">
            <Modal
                isOpen={questionOpen}
                onRequestClose={() => setQuestionOpen(false)}
                style={questionStyles}
                contentLabel="Example Modal"
            >
                <button
                    className="payment-modal__close-btn"
                    onClick={() => setQuestionOpen(false)}
                >
                    <span>&times;</span>
                </button>
                <Typography
                    className="text-center mb-2 mt-2 question-modal-heading"
                    element={`CAMPAIGN : ${item.name ? item.name : "current"}`}
                />

                <div className="col-lg-4 me-auto ms-auto text-center mt-4 overflow-auto">
                    <button className="btn btn-primary" onClick={addForm}>
                        +
                    </button>
                    {forms.map((form: any) => (
                        <form
                            className="border p-2 mt-2"
                            key={form.formId}
                            onSubmit={(e) =>
                                handleFormSubmit(
                                    form.formId,
                                    e,
                                    setQuestionOpen
                                )
                            }
                        >
                            <div className="singlebox">
                                <div className="form-group">
                                    <label
                                        htmlFor={`selectOption${form.formId}`}
                                    >
                                        Select Option:
                                    </label>
                                    <select
                                        className="form-control"
                                        id={`selectOption${form.formId}`}
                                        value={form.selectedOption}
                                        onChange={(e: any) =>
                                            handleOptionChange(
                                                form.formId,
                                                e.target.value
                                            )
                                        }
                                    >
                                        <option value="Text">TEXT</option>
                                        <option value="Multiple Choice">
                                            MCQ
                                        </option>
                                        <option value="Checkbox">
                                            Checkbox
                                        </option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label
                                        htmlFor={`inputOption${form.formId}`}
                                    >
                                        QUESTION:
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id={`inputOption${form.formId}`}
                                        placeholder="Enter a question"
                                        value={form.inputOption}
                                        onChange={(e: any) =>
                                            handleInputChange(
                                                form.formId,
                                                e.target.value
                                            )
                                        }
                                    />
                                </div>
                                {form.selectedOption === "Multiple Choice" && (
                                    <div className="form-group">
                                        <label
                                            className="text-left"
                                            htmlFor={`choices${form.formId}`}
                                        >
                                            OPTIONS:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id={`choices${form.formId}`}
                                            placeholder="Enter options"
                                            value={form.choices}
                                            onChange={(e: any) =>
                                                handleChoicesChange(
                                                    form.formId,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                {form.selectedOption === "Checkbox" && (
                                    <div className="form-group">
                                        <label
                                            htmlFor={`choices${form.formId}`}
                                        >
                                            CHECKBOX:
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id={`choices${form.formId}`}
                                            placeholder="Enter options"
                                            value={form.choices}
                                            onChange={(e: any) =>
                                                handleChoicesChange(
                                                    form.formId,
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                )}
                                <button
                                    className="btn btn-danger m-2"
                                    onClick={() => removeForm(form.formId)}
                                >
                                    Delete
                                </button>
                                {/* <button
                                    type="submit"
                                    className="btn btn-primary mt-2"
                                >
                                    Submit
                                </button> */}
                            </div>
                        </form>
                    ))}
                    <button
                        onClick={handleFormSubmitAll}
                        className="btn btn-primary  ms-2"
                    >
                        Submit All Forms
                    </button>
                </div>
            </Modal>
        </div>
    );
}
