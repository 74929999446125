// import { Dropdown } from "react-bootstrap";
// import CampaignFeatureElement from "./campaign-feature-element";

// function StatusDropdown({ item }: any) {
//     return (
//         <Dropdown>
//             <Dropdown.Toggle variant="primary" id="dropdown-status">
//                 Status
//             </Dropdown.Toggle>
//             <Dropdown.Menu>
//                 <CampaignFeatureElement
//                     item={item}
//                     className={
//                         item.status === "COMPLETED"
//                             ? "btn btn-primary mr-2 mb-2 ms-2"
//                             : "btn btn-danger mr-2 mb-2 ms-2"
//                     }
//                     element="COMPLETED"
//                     event="status"
//                 />
//                 <CampaignFeatureElement
//                     item={item}
//                     className={
//                         item.status === "RUNNING"
//                             ? "btn btn-primary mr-2 mb-2 ms-2"
//                             : "btn btn-danger mr-2 mb-2 ms-2"
//                     }
//                     element="RUNNING"
//                     event="status"
//                 />
//                 <CampaignFeatureElement
//                     item={item}
//                     className={
//                         item.status === "PENDING"
//                             ? "btn btn-primary mr-2 ms-2"
//                             : "btn btn-danger mr-2 ms-2"
//                     }
//                     element="PENDING"
//                     event="status"
//                 />
//             </Dropdown.Menu>
//         </Dropdown>
//     );
// }

// export default StatusDropdown;
import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { handleCampaignStatusSubmit } from "../../admin.actions";

function StatusDropdown({ item }: any) {
    const [selectedStatus, setSelectedStatus] = useState(item.status);

    const handleStatusChange = (status: string) => {
        setSelectedStatus(status);
        handleCampaignStatusSubmit(item, status); // Pass the updated `status` value
    };

    return (
        <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-status">
                {selectedStatus}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item
                    className={
                        selectedStatus === "COMPLETED"
                            ? "btn btn-primary mr-2 mb-2 ms-2"
                            : "btn btn-danger mr-2 mb-2 ms-2"
                    }
                    onClick={() => handleStatusChange("COMPLETED")}
                >
                    COMPLETED
                </Dropdown.Item>
                <Dropdown.Item
                    className={
                        selectedStatus === "RUNNING"
                            ? "btn btn-primary mr-2 mb-2 ms-2"
                            : "btn btn-danger mr-2 mb-2 ms-2"
                    }
                    onClick={() => handleStatusChange("RUNNING")}
                >
                    RUNNING
                </Dropdown.Item>
                <Dropdown.Item
                    className={
                        selectedStatus === "PENDING"
                            ? "btn btn-primary mr-2 ms-2"
                            : "btn btn-danger mr-2 ms-2"
                    }
                    onClick={() => handleStatusChange("PENDING")}
                >
                    PENDING
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default StatusDropdown;
