import Accordion from "react-bootstrap/Accordion";

function Terms() {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    By Submitting I Accept The Terms & Conditions
                </Accordion.Header>
                <Accordion.Body>
                    I am above 18 and agree to provide the information
                    voluntarily which may be used for research purposes
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default Terms;
