export const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: " 310px",
        padding: "10px 20px",
    },
};

export const campaignFeatureStyle = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        // bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: " 500px",
        height: "300px",
    },
};

export const questionStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: " 1140px",
        height: " 840px",
        padding: "10px 20px",
        overflow: "auto",
    },
};
