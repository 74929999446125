export const headers: string[] = [
    "id",
    "name",
    "createdBy",
    "status",
    "time",
    "impression",
    "totatlBA",
    "allow_duplicate",
    "allow_duplicate_across_campaigns",
    "digital_reward",
    "description",
];
