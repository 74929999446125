// import { useGetCampaign } from "../hooks/useGetCampaign";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { useState, useRef } from "react";
// import { success, error } from "../../../core/common/toaster";

// interface Campaign {
//     id: number;
//     choices: {
//         id: number;
//         text: string;
//         question: number;
//     }[];
//     question_type: string;
//     text: string;
//     created_at: string;
//     updated_at: string;
//     campaign: number;
//     userAnswer: string | string[];
// }

// interface Props {
//     campaigns: Campaign[];
//     loading: boolean;
// }

// const useQuestionSubmit = () => {
//     const navigate = useNavigate();
//     const { campaigns, loading }: Props = useGetCampaign();
//     const signatureRef: any = useRef(null);
//     const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
//     const [agreed, setAgreed] = useState(false);

//     const handleAgreementCheck = (e: any) => {
//         setAgreed(e.target.checked);
//     };

//     const clearSignature = () => {
//         signatureRef.current.clear();
//         setIsSignatureEmpty(true);
//     };

//     const handleSignatureChange = () => {
//         if (signatureRef.current.isEmpty()) {
//             setIsSignatureEmpty(true);
//         } else {
//             setIsSignatureEmpty(false);
//         }
//     };

//     let rewardData;

//     const access_token = `Token ${localStorage.getItem("access")}`;
//     const participant_id: any = localStorage.getItem("participant_id");

//     const submitSurvey: any = async (values: any) => {
//         const data = campaigns.map((campaign: Campaign) => ({
//             question: campaign.text,
//             answer: values[`question-${campaign.id}`],
//         }));

//         const formData = new FormData();

//         formData.append(
//             "participant_id",
//             JSON.stringify(parseInt(participant_id))
//         );

//         formData.append("signature", "True");
//         formData.append("survey_response", JSON.stringify(data));
//         console.log("survey_response", JSON.stringify(data));

//         const headers = {
//             Authorization: access_token,
//         };

//         try {
//             const response = await axios.post(
//                 "https://app.qik-check.com/campaign/submit-survey/",
//                 formData,
//                 {
//                     headers,
//                 }
//             );
//             rewardData = response.data.rewardData;
//             localStorage.setItem("rewardData", JSON.stringify(rewardData));
//             if (agreed) {
//                 success();
//                 return navigate("/survey/reward");
//             }
//             success();
//             localStorage.removeItem("participant_id");
//             localStorage.removeItem("rewardData");
//             setTimeout(() => {
//                 return navigate("/");
//             }, 1000);
//         } catch (err) {
//             error();
//         }
//     };

//     const campaignsWithAnswers = campaigns.map((campaign) => ({
//         ...campaign,
//         userAnswer: "",
//     }));

//     return {
//         submitSurvey,
//         campaigns: campaignsWithAnswers,
//         isSignatureEmpty,
//         clearSignature,
//         handleSignatureChange,
//         signatureRef,
//         agreed,
//         setAgreed,
//         handleAgreementCheck,
//         loading,
//     };
// };

// export { useQuestionSubmit };

import { useGetCampaign } from "../hooks/useGetCampaign";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import { success, error } from "../../../core/common/toaster";

interface Campaign {
    id: number;
    choices: {
        id: number;
        text: string;
        question: number;
    }[];
    question_type: string;
    text: string;
    created_at: string;
    updated_at: string;
    campaign: number;
    userAnswer: string | string[];
}

interface Props {
    campaigns: Campaign[];
    loading: boolean;
}

const useQuestionSubmit = () => {
    const navigate = useNavigate();
    const { campaigns, loading }: Props = useGetCampaign();
    const signatureRef: any = useRef(null);
    const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
    const [agreed, setAgreed] = useState(false);

    const handleAgreementCheck = (e: any) => {
        setAgreed(e.target.checked);
    };

    const clearSignature = () => {
        signatureRef.current.clear();
        setIsSignatureEmpty(true);
    };

    const handleSignatureChange = () => {
        if (signatureRef.current.isEmpty()) {
            setIsSignatureEmpty(true);
        } else {
            setIsSignatureEmpty(false);
        }
    };

    let rewardData;

    const access_token = `Token ${localStorage.getItem("access")}`;
    const participant_id: any = localStorage.getItem("participant_id");

    const submitSurvey: any = async (values: any) => {
        const data = campaigns.map((campaign: Campaign) => ({
            question: campaign.text,
            answer: values[`question-${campaign.id}`],
        }));

        const formData = new FormData();

        formData.append(
            "participant_id",
            JSON.stringify(parseInt(participant_id))
        );

        formData.append("signature", "True");
        formData.append("survey_response", JSON.stringify(data));

        const headers = {
            Authorization: access_token,
        };

        try {
            const response = await axios.post(
                "https://app.qik-check.com/campaign/submit-survey/",
                formData,
                {
                    headers,
                }
            );
            rewardData = response.data.rewardData;
            localStorage.setItem("rewardData", JSON.stringify(rewardData));
            if (agreed) {
                success();
                return navigate("/survey/reward", { replace: true });
            }
            success();
            localStorage.removeItem("participant_id");
            localStorage.removeItem("rewardData");
            setTimeout(() => {
                return navigate("/", { replace: true });
            }, 1000);
        } catch (err) {
            error();
        }
    };

    const campaignsWithAnswers = campaigns.map((campaign) => ({
        ...campaign,
        userAnswer: "",
    }));

    return {
        submitSurvey,
        campaigns: campaignsWithAnswers,
        isSignatureEmpty,
        clearSignature,
        handleSignatureChange,
        signatureRef,
        agreed,
        setAgreed,
        handleAgreementCheck,
        loading,
    };
};

export { useQuestionSubmit };
